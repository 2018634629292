import styled from "styled-components";
import { Color } from "../../utils/styleUtils";
import DropArrow from "../../assets/images/arrow-down.svg";
import BannerBg from "../../assets/images/banner-pattern.png";
import MinusIcon from "../../assets/images/minus-icon.svg";
import NextIcon from "../../assets/images/next-icon.svg";
import PlusIcon from "../../assets/images/plus-lg.svg";
import PrevIconOne from "../../assets/images/prev-icon-one.svg";
import PrevIcon from "../../assets/images/prev-icon.svg";

export const HomeBanner = styled.div`
  background-color: #940e34;
  background-image: url(${BannerBg});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 740px;
  @media (max-width: 1440px) {
    min-height: 680px;
  }
  @media (max-width: 1366px) {
    min-height: 610px;
  }
`;
export const HomeBannerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  @media (max-width: 700px) {
    display: block !important;
    justify-content: center;
  }
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1366px) {
    padding-top: 35px;
  }
`;

export const HomeBannerLeft = styled.div`
  padding: 20px 0 0;
  max-width: 54%;
  flex: 54%;
  @media (max-width: 700px) {
    max-width: 100%;
    padding-bottom: 3rem;
  }
`;
export const HomeBannerTitle = styled.h1`
  color: #ffffff;
  font-size: 60px;
  line-height: 135%;
  font-weight: 800;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
  @media (max-width: 1440px) {
    font-size: 42px;
  }
  @media (max-width: 1366px) {
    font-size: 36px;
  }
  span {
    color: #940e34;
    font-size: 48px;
    line-height: 110%;
    display: inline-block;
    padding: 15px 40px;
    background: #ffffff;
    border-radius: 40px;
    box-shadow: inset 5px 3px 0px #dfdfdf, 6px 6px 0px #5e0720;
    transform: rotate(-12deg);
    vertical-align: 25px;
    @media (max-width: 1440px) {
      font-size: 32px;
    }
    @media (max-width: 1366px) {
      font-size: 28px;
      padding: 12px 28px;
    }
  }
  img {
    position: absolute;
    right: -60px;
    bottom: -45px;
    @media (max-width: 1440px) {
      max-width: 110px;
      right: -30px;
    }
    @media (max-width: 1366px) {
      max-width: 90px;
    }
  }
`;
export const SearchBox = styled.div`
  box-shadow: 0px 0px 50px #0128341a;
  background: #ffffff;
  padding: 30px;
  border-radius: 20px;
  .CloseBtn {
    position: relative;
    .resetbtn {
      position: absolute;
      top: 22px;
      right: 10px;
      height: 20px;
      width: 20px;
      color: #940e34;
      opacity: 0.6;
      @media (max-width: 1440px) {
        top: 14px;
      }
    }
  }
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      border-radius: 30px;
      background: #f7f7f7;
      padding: 20px;
      font-size: 14px;
      line-height: 130%;
      font-weight: 400;
      color: #012834;
      border: 1px solid #f7f7f7;
      @media (max-width: 1440px) {
        padding: 14px 20px;
      }
      &::after {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: url(${DropArrow});
        height: 8px;
        width: 14px;
        border: none;
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 20px;
      background: #f7f7f7;
      padding: 15px 0;
      border: none;
      .dropdown-item {
        padding: 8px 25px;
      }
    }
  }
  .btn-wrap {
    margin: 20px 0 0 0;
    text-align: center;
    .btn {
      width: 100%;
      &:focus-visible {
        background: #940e34;
        color: #ffffff;
        border-color: #940e34;
        box-shadow: none;
      }
      &:active {
        background: #940e34;
        color: #ffffff;
      }
      & + .btn {
        margin-left: 20px;
      }
    }
  }
  .ant-select-selector {
    /* border-radius: 30px; */
    background-color: #f7f7f7 !important;
    font-size: 14px;
    line-height: 130%;
    font-weight: 400;
    color: #012834;
    border: 1px solid #f7f7f7 !important;
    min-height: 60px;
    padding: 5px 36px 5px 20px;
    @media (max-width: 1440px) {
      min-height: 48px;
    }
    .ant-select-selection-placeholder {
      inset-inline-start: 20px;
      inset-inline-end: 20px;
      color: #707070;
    }
    .ant-select-selection-item {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 20px;
      padding: 3px 5px;
      height: 26px;
      font-size: 14px;
      line-height: 120%;
      font-weight: 400;
      color: #012834;
      .anticon {
        vertical-align: middle;
        svg {
          fill: #940e34;
        }
      }
    }
  }
`;
export const HomeBannerRight = styled.div`
  max-width: calc(100% - 54%);
  flex: calc(100% - 54%);
  position: relative;
  @media (max-width: 700px) {
    display: none !important;
  }
  .carousel {
    padding-left: 140px;
    margin-right: -150px;
    padding-top: 60px;
    @media (max-width: 1680px) {
      padding-left: 70px;
      margin-right: -70px;
    }
    @media (max-width: 1440px) {
      padding-left: 30px;
      margin-right: -50px;
    }
    @media (max-width: 1366px) {
      padding-left: 20px;
    }
  }
`;
export const BannerTeacher = styled.div`
  position: relative;
  img {
    max-height: 620px;
    @media (max-width: 1440px) {
      max-height: 600px;
    }
    @media (max-width: 1366px) {
      max-height: 515px;
    }
  }
`;
export const TeacherMessage = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 17px;
  width: 315px;
  position: absolute;
  right: 0;
  top: 20px;
  color: #012834;
  font-size: 20px;
  line-height: 140%;
  font-weight: 600;
  @media (max-width: 1440px) {
    font-size: 17px;
    width: 266px;
  }
  @media (min-width: 998px) {
    display: none;
  }
  @media (max-width: 1366px) {
    font-size: 15px;
    width: 240px;
  }
  &::before {
    position: absolute;
    content: "";
    bottom: -24px;
    left: 30px;
    display: block;
    border-left: 0px solid transparent;
    border-right: 40px solid transparent;
    border-top: 25px solid #ffffff;
  }
  span {
    color: #940e34;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    @media (max-width: 1440px) {
      font-size: 19px;
    }
    @media (max-width: 1366px) {
      font-size: 17px;
    }
  }
`;
export const PopularSection = styled.div`
  padding: 100px 0 70px;
  @media (max-width: 1680px) {
    padding: 90px 0 60px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 50px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 45px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 40px;
  }
  .nav-tabs {
    max-width: 700px;
    border-radius: 0;
    margin: 0 auto 60px;
    border: none;
    @media (max-width: 1680px) {
      margin: 0 auto 50px;
    }
    @media (max-width: 1440px) {
      margin: 0 auto 40px;
    }
    @media (max-width: 1366px) {
      margin: 0 auto 30px;
    }
    @media (max-width: 1280px) {
      margin: 0 auto 30px;
    }
    .nav-item {
      background: #ffffff;
      width: calc(50% - 30px);
      margin: 0 15px;
      .nav-link {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(112, 112, 112, 0.2);
        color: #012834;
        font-size: 24px;
        line-height: 100%;
        font-weight: 400;
        padding: 26px;
        @media (max-width: 1440px) {
          font-size: 22px;
          line-height: 100%;
          padding: 20px;
        }
        @media (max-width: 1280px) {
          font-size: 20px;
          line-height: 120%;
        }
        &.active {
          color: #940e34;
          border-bottom: 2px solid #940e34;
          font-weight: 600;
        }
      }
    }
  }
`;

export const TutionGroup = styled.ul`
  padding: 0;
  margin: 0 -15px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .tution-list {
    border-radius: 30px;
    flex: 25%;
    max-width: 25%;
    margin: 0 0 30px;
    padding: 0 15px;
    @media (max-width: 1440px) {
      margin: 0 0 15px;
      padding: 0 10px;
    }
  }
`;
export const TutionListWrap = styled.div`
  padding: 20px;
  border-radius: 30px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  @media (max-width: 1440px) {
    margin: 20px 0 0 0;
    padding: 16px;
  }
  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 100%;
    padding: 15px;
    min-height: 180px;
  }
`;
export const TutionImage = styled.div``;
export const TutionTitle = styled.h4`
  color: #012834;
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  margin: 26px 0 0 0;
  text-align: center;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 120%;
    margin: 20px 0 0 0;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 120%;
    margin: 20px 0 0 0;
  }
`;
export const ReviewSection = styled.div`
  background: #f0f0f0;
  padding: 100px 0;
  @media (max-width: 1680px) {
    padding: 90px 0;
  }
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 60px 0;
  }
  @media (max-width: 1280px) {
    padding: 50px 0;
  }
  .slick-slider {
    margin: 0 -15px;
    .slick-track {
      display: flex;
      .slick-slide {
        height: inherit;
        & > div {
          height: 100%;
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: -80px;
      @media (max-width: 1680px) {
        top: -70px;
      }
      @media (max-width: 1440px) {
        top: -60px;
      }
      @media (max-width: 1366px) {
        top: -50px;
      }
      @media (max-width: 1280px) {
        top: -40px;
      }
      &.slick-next {
        width: 45px;
        height: 30px;
        right: 15px;
        @media (max-width: 1440px) {
          width: 40px;
          height: 25px;
          right: 20px;
        }
        @media (max-width: 1366px) {
          width: 30px;
          height: 20px;
          right: 30px;
        }
        &.slick-disabled {
          &::before {
            content: "";
            background-image: url(${PrevIconOne});
            background-size: cover;
            transform: rotate(180deg);
          }
        }
        &::before {
          content: "";
          background-image: url(${NextIcon});
          background-size: cover;
          width: 45px;
          height: 30px;
          opacity: 1;
          display: block;
        }
      }
      &.slick-prev {
        width: 45px;
        height: 30px;
        right: 90px;
        left: auto;
        @media (max-width: 1440px) {
          width: 40px;
          height: 25px;
          right: 100px;
        }
        @media (max-width: 1366px) {
          width: 30px;
          height: 20px;
        }
        &.slick-disabled {
          &::before {
            content: "";
            background-image: url(${PrevIconOne});
            background-size: cover;
          }
        }
        &::before {
          content: "";
          background-image: url(${PrevIcon});
          background-size: cover;
          width: 45px;
          height: 30px;
          opacity: 1;
          display: block;
        }
      }
    }
  }
`;
export const ReviewTitle = styled.h2`
  color: #012834;
  font-size: 42px;
  line-height: 130%;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
  margin-bottom: 55px;
  @media (max-width: 1680px) {
    font-size: 40px;
    margin-bottom: 50px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @media (max-width: 1366px) {
    font-size: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
`;

export const ReviewCard = styled.div`
@media (max-width: 600px) {
  margin-top: 60px;
}`;

export const StudentsData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StudentsCard = styled.div`
  padding: 0 15px;
`;
export const StudentsName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1680px) {
    margin-bottom: 18px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 15px;
  }
  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }
`;
export const StudentsImg = styled.div`
  // flex: 0 0 15.35%;
  max-width: 15.35%;
  height: 60px;
  width: 60px;
  @media (max-width: 1366px) {
    height: 50px;
    width: 50px;
  }
  img {
    height: 100%;
    width: 100%;
  }
`;
export const StudentsReview = styled.div`
  flex: 0 0 79.65%;
  max-width: 79.65%;
  height: 60px;
  width: 60px;
  h4 {
    font-size: 20px;
    line-height: 24px;
    color: #940e34;
    font-weight: 700;
    margin-bottom: 0px;
    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #012834;
    opacity: 0.5;
    margin-bottom: 0px;
  }
`;
export const StudentsDetails = styled.div`
  font-size: 16px;
  color: #012834;
  font-weight: 400;
  opacity: 0.8;
  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const StudentsStar = styled.div`
  span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #012834;
    // opacity: 0.5;
    margin-bottom: 0px;
  }
  ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0;
    li {
      padding-right: 10px;
      img {
        height: 22px;
        width: auto;
        margin-top: 15px;
        opacity: 0.4;
        @media (max-width: 1440px) {
          height: 20px;
          width: auto;
          margin-top: 10px;
        }
      }
      &.active {
        img {
          opacity: 1;
        }
      }
    }
  }
`;
export const StudentsCardWrap = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  height: fit-content;
  font-family: Montserrat, sans-serif;
  @media (max-width: 1680px) {
    padding: 25px;
  }

  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
    background-color: ${Color.bgLight};

    @media (max-width: 991px) {
      height: 200px;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  h6 {
    margin-bottom: 0;
    font-size: 14px;
    opacity: 0.5;
  }
`;
export const FaqSection = styled.div`
  padding: 100px 0px 158px;
  @media (max-width: 1680px) {
    padding: 90px 0 140px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 120px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 90px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 70px;
  }
`;
export const FaqTitle = styled.h2`
  color: #012834;
  font-size: 42px;
  line-height: 50px;
  font-weight: 800;
  margin-bottom: 30px;
  @media (max-width: 1680px) {
    font-size: 40px;
    margin-bottom: 25px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-bottom: 15px;
  }
  @media (max-width: 1366px) {
    font-size: 32px;
    margin-bottom: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 30px;
  }
`;
export const FaqContent = styled.div`
  color: #000000;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 60px;
  @media (max-width: 1680px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 40px;
  }
  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
  }
`;
export const FaqImg = styled.div`
  padding-left: 85px;
  @media (max-width: 1680px) {
    padding-left: 75px;
  }
  @media (max-width: 1440px) {
    padding-left: 60px;
  }
  @media (max-width: 1366px) {
    padding-left: 50px;
  }
  @media (max-width: 1366px) {
    padding-left: 40px;
  }
  img {
    max-width: 83.1%;
  }
`;
export const FaqAccordion = styled.div`
  padding-left: 10px;
  @media (max-width: 1440px) {
    padding-left: 0px;
  }
  .accordion-item {
    border: 1px solid transparent;
    margin-top: 20px;
    @media (max-width: 1440px) {
      margin-top: 12px;
    }
    @media (max-width: 1366px) {
      margin-top: 8px;
    }
    .accordion-header {
      .accordion-button {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        text-align: left;
        letter-spacing: 0px;
        color: #012834;
        background-color: #f7f7f7;
        box-shadow: none;
        border-radius: 63px;
        padding: 28px 70px 28px 30px;
        position: relative;
        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 22px;
          border-radius: 50px;
          padding: 20px 70px 20px 20px;
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 22px;
          border-radius: 40px;
          padding: 20px 70px 20px 20px;
        }
        &:not(.collapsed)::after {
          background: url(${MinusIcon});
          background-repeat: no-repeat;
          right: 30px;
          transform-origin: center;
          transition: all 0.5s ease-in-out;
          transform: rotate(0deg);
          @media (max-width: 1440px) {
            right: 27px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          margin-left: auto;
          background: url(${PlusIcon});
          background-repeat: no-repeat;
          border-radius: 50%;
          right: 28px;
          transform-origin: center;
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg);
          @media (max-width: 1440px) {
            right: 23px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          background-color: #ffffff;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          right: 10px;
          box-shadow: 0px 3px 20px #0000000f;
          opacity: 1;
          @media (max-width: 1440px) {
            height: 50px;
            width: 50px;
            right: 10px;
          }
        }
      }
    }
    .accordion-body {
      font-size: 16px;
      line-height: 30px;
      color: #012834;
      background-color: #f7f7f7;
      box-shadow: none;
      border-radius: 30px;
      padding: 30px;
      margin-top: 10px;
      opacity: 1;
      @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 26px;
        border-radius: 20px;
        padding: 20px;
        margin-top: 5px;
      }
    }
  }
`;
