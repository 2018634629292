import { AutoComplete, Select, SelectProps } from "antd";
import _ from "lodash";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  getAreasList,
  getLevels,
  getLocationList,
  getSubject,
  getareaById,
} from "../../api/list";
import BannerArrow from "../../assets/images/banner-arrow.svg";
import Teacher from "../../assets/images/teacher.png";
import NewTeacher from "../../assets/images/teacher2.png";
import Footer from "../../components/Footer/index";
import Header from "../../components/Header";
import FaqSectionList from "../../components/HomePage/faqSection";
import PopularTutionSubjects from "../../components/HomePage/popularTutionSubjects";
import ReviewSection from "../../components/HomePage/reviewSection";
import Testimonial from "../../components/HomePage/testimonial";
import { SearchTution } from "../../redux/action/searchAction";
import { Layout } from "antd";
import "./index.scss";
// import "antd/dist/antd.css";
// import SearchableDropdown from "../../components/SearchableInput/SearchableDropdown";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";?\

import {
  BannerTeacher,
  HomeBanner,
  HomeBannerLeft,
  HomeBannerRight,
  HomeBannerTitle,
  HomeBannerWrap,
  SearchBox,
  TeacherMessage,
} from "./homeStyle";
import LocationInfo, { LocationType } from "./locationInfo";
import SearchForm from "./SearchForm";


const days = [
  "All days",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
type HomePageTypes = {
  location: string;
  subject: string;
  selectArea: string;
  daysOfWeek: string;
  selectTiming: string;
  selectLevel: string;
};
const { Option } = Select;

const { Content, Footer: LayoutFooter } = Layout;

const Home: Function = () => {
  const [level, setLevel] = useState<any>([]);
  const [citysearch, setCitysearch] = useState<any>("");
  const [cityID, setCityID] = useState<string>("");
  const [areasearch, setAreasearch] = useState<any>([]);
  const [subjectSearch, setSubjectsearch] = useState<any>();
  const [cityList, setCityList] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [filteredArea, setFilteredarea] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [className, setClassName] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState({
    location: "",
    subject: "",
    selectArea: "",
    daysOfWeek: "",
    selectTiming: "",
    level: "",
    fieldValue: "",
  });
  const [autoCompleteLableValue, setAutoCompleteLableValue] = useState<any>({
    city: "",
    subject: "",
  });
  const [subject, setsubject] = useState([]);
  const [city, setCity] = useState<any>([]);
  const [value, setValue] = useState("");
  const [optionss, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [optionsList, setOptionsList] = useState<any>([]);
  const [optionsList1, setOptionsList1] = useState<any>([]);

  const navigate = useNavigate();

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  const submitForm = (values: any) => {
    const payload = {
      city: values.location,
      area: values.area,
      subject: values.subject,
      level: values.level,
      days: values.daysOfWeek ? parseInt(values.daysOfWeek) : 0,
      batch_id: "",
    };

    SearchTution(payload);
    navigate(
      `${"/tuition-list"}?LOCATION_NAME=${
        payload.city
      }&SELECT_AREA=${
        payload.area
      }&SELECT_LEVEL=${
        payload.level
      }&DAYS_OF_WEEK=${
        payload.days
      }&TIME=${
        values.timing
      }&SUBJECT_NAME=${
        payload.subject
      }`
    );
  };

  const filteredOptions = LocationInfo?.filter((option: any) => {
    return option?.location?.toLowerCase()?.includes(searchTerm?.toLowerCase());
  });

  const handleInputChange = (e: any) => {
    if (e.target.value.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    console.log(e.target.value, "e.target.value");
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (option: LocationType, setFieldValue: any) => {
    setFieldValue("selectArea", option.area);
    setOpen(false);
    setSearchTerm(option.location);
  };
  const getallSubjects = () => {
    getSubject().then((res) => {
      if (res?.records) {
        const subjectData = _.chain(res.records)
          .map((item: any) => ({ label: item.subject, value: item.subject }))
          .sortBy(["label"])
          .value();
        setOptionsList1(subjectData);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getallLevels();
    getallSubjects();

    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = _.chain(res.records)
          .map((item: any) => ({ id: item.id, label: item.location, value: item.location }))
          .sortBy(["label"])
          .value();
        setCityList(cityData);
        setOptionsList(cityData);
        console.log("subject from city", cityData);
      }
    });
  }, []);

  const isValidInfo = (values: any) => {
    console.log("dataaaaaaaaaaaaa11", values);
    let isValid = false;

    if (citysearch?.length > 0) {
      isValid = true;
    } else if (values.subject.length > 0) {
      isValid = true;
    } else if (values.selectArea.length > 0) {
      isValid = true;
    } else if (values.daysOfWeek.length > 0) {
      isValid = true;
    } else if (values.selectTiming.length > 0) {
      isValid = true;
    }

    return isValid;
  };
  
  const handleClearAutoComplete = () => {
    setAutoCompleteLableValue({
      city: "",
      subject: "",
    });
  };
  const getallLevels = () => {
    getLevels().then((res: any) => {
      console.log('levels', res);
      if (res?.records) {
        const levelData = _.chain(res.records)
          .map((item: any) => ({ label: item.level_name, value: item.level_name }))
          .sortBy(["label"])
          .value();
        setLevel(levelData);
      }
    });
  };

  const UserData = level?.map((item: any) => {
    return {
      value: item?.level_name,
      label: item?.level_name,
      id: item?.id,
    };
  });

  const handlesetValue = (data: any) => {
    setSelectedOption(data);
    setSearchValue({
      ...searchValue,
      level: data,
    });
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    filterAreas();
  }, [citysearch]);
  const filterAreas = () => {
    if (citysearch?.length > 0) {
      const cityid = cityList?.find((c: any) => c?.label === citysearch)?.id;
      const belongedareas = areaList?.filter(
        (a: any) => a?.locationid == cityid
      );
      setFilteredarea(belongedareas);
    }
  };

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    // if (!value || value.indexOf("@") >= 0) {
    //   res = [];
    // } else {
    //   res = ["gmail.com", "163.com", "qq.com"].map((domain) => ({
    //     value,
    //     label: `${value}@${domain}`,
    //   }));
    // }

    getLocationList().then((res) => {
      if (res?.records) {
        const cityData = res?.records?.map((item: any) => ({
          value: item?.location,
          id: item?.id,
          label: item?.location,
        }));
        setOptions(cityData);
      }
    });
    // setOptions(res);
  };
  const getCertainArea = (data: any) => {
    setCityID(data);
  
    getareaById(data).then((res) => {
      if (res?.records) {
        // Map the response records to the desired format
        let areaData = res.records.map((item: any) => ({
          value: item?.area,
          id: item?.id,
          label: item?.area,
        }));
  
        // Sort the mapped area data in ascending order based on the 'label' property
        areaData = areaData.sort((a: any, b: any) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
  
        // Set the sorted area data to state
        setAreaList(areaData);
      }
    });
  };
  
  const areAllFieldsEmpty = (
    values: any,
    citysearch: any,
    areasearch: any,
    subjectSearch: any
  ) => {
    return (
      !citysearch &&
      !areasearch &&
      !values.subject &&
      !values.level &&
      !values.daysOfWeek &&
      !values.selectTiming &&
      !subjectSearch
    );
    // if(
    //   !citysearch
    //   !areasearch &&
    // !values.subject &&
    // !values.level &&
    // !values.daysOfWeek &&
    // !values.selectTiming &&
    //   !subjectSearch){
    //     return
    //   }
    // else{}
  };
  const handleSearchCity = (value: any) => {
    if (!value) {
      setOptionsList(cityList);
    } else {
      const filteredOptions = cityList?.filter((option: any) =>
        option?.label?.toLowerCase()?.includes(value.toLowerCase())
      );
      setOptionsList(filteredOptions);
    }
  };

  const handleSearchSubject = (value: any) => {
    if (!value) {
      setOptionsList1([]);
    } else {
      // If there is a search value, filter the options
      let filteredOptions = subject?.filter((option: any) =>
        option?.label?.toLowerCase()?.includes(value.toLowerCase())
      );
      filteredOptions = filteredOptions?.slice(0, 4);
      setOptionsList1(filteredOptions);
    }
  };
  return (
    <Layout className="home-layout">
      <Header />
      
      <Content className="home-layout-content">
        <HomeBanner>
          <Container>
            <HomeBannerWrap>
              <HomeBannerLeft>
                <HomeBannerTitle>
                  Find your <span>Tuition</span>
                  <br />
                  in a few clicks.
                  <img src={BannerArrow} alt="Right Arrow" />
                </HomeBannerTitle>
                <SearchBox>
                  <SearchForm cityList={cityList} subjectList={optionsList1} levelsList={level} onSubmit={submitForm} />
                  {/* <Formik
                    initialValues={{
                      location: "",
                      subject: "",
                      selectArea: "",
                      daysOfWeek: "",
                      selectTiming: "",
                      selectLevel: "",
                      level: selectedOption?.id,
                    }}
                    validate={(values: HomePageTypes) => {
                      let errors: any = {};
                      if (citysearch?.length < 1) {
                        errors.location = "Please enter your location";
                      } else {
                        const isCityInOptions = optionsList.some(
                          (city: any) =>
                            city.value.toLowerCase() ===
                            citysearch.toLowerCase()
                        );
                        if (!isCityInOptions) {
                          errors.location = "Please enter available location";
                        }
                      }
                      return errors;
                    }}
                    onSubmit={submitForm}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                      validateForm,
                    }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="form-group CloseBtn">
                                <AutoComplete
                                  style={{ width: "100%" }}
                                  onSearch={handleSearchCity}
                                  options={optionsList}
                                  value={citysearch}
                                  onChange={(value: any, option: any) => {
                                    setCitysearch(value);
                                    setAreasearch([]);
                                    errors.location = "";
                                    getCertainArea(option?.id);
                                    setAutoCompleteLableValue({
                                      ...autoCompleteLableValue,
                                      city: option?.value,
                                    });
                                  }}

                                  placeholder="Enter City *"
                                />
                                {citysearch?.length > 0 && (
                                    <>
                                      <AiOutlineCloseCircle
                                        type="reset"
                                        className="resetbtn"
                                        onClick={() => {
                                          setCitysearch('');

                                        }}
                                      >
                                        close
                                      </AiOutlineCloseCircle>
                                    </>
                                  )}
                                <div className="error">
                                  {errors.location ? errors.location : null}
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg={6} className="mt-4 mt-lg-0">
                              <Form.Group className="form-group CloseBtn">
                                <Select
                                  style={{
                                    width: "100%",
                                  }}
                                  showSearch
                                  placeholder="Select Area"
                                  optionFilterProp="children"
                                  disabled={
                                    citysearch === "" || citysearch.length === 0
                                  }
                                  onChange={(e: any) => {
                                    setAreasearch(e);
                                  }}
                                  filterOption={filterOption}
                                  value={areasearch}
                                  options={areaList}
                                ></Select>
                                {areasearch?.length > 0 && (
                                  <>
                                    <AiOutlineCloseCircle
                                      type="reset"
                                      className="resetbtn"
                                      onClick={() => {
                                        setAreasearch([]);
                                      }}
                                    >
                                      close
                                    </AiOutlineCloseCircle>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row  className="mt-4 d-flex flex-col flex-lg-row  space-around w-100">
                            <Col lg={6} >
                              <Form.Group className="CloseBtn">
                                <AutoComplete
                                  style={{ width: "100%" }}
                                  onSearch={handleSearchSubject}
                                  options={optionsList1}
                                  value={subjectSearch}
                                  onChange={(e: any, value: any) => {
                                    setSubjectsearch(e);
                                    setAutoCompleteLableValue({
                                      ...autoCompleteLableValue,
                                      subject: value.value,
                                    });
                                  }}
                                  placeholder="Enter Subject"
                                />
                                 {subjectSearch?.length > 0 && (
                                  <>
                                    <AiOutlineCloseCircle
                                      type="reset"
                                      className="resetbtn"
                                      onClick={() => {
                                        setSubjectsearch([]);
                                      }}
                                    >
                                      close
                                    </AiOutlineCloseCircle>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="mt-5 mt-lg-0">
                              <Form.Group className="CloseBtn">
                                <Form.Select
                                  id="dropdown-level"
                                  title="Select Level"
                                  name="level"
                                  value={selectedOption}
                                  onChange={(e: any) => {
                                    handlesetValue(e?.target?.value);
                                  }}
                                >
                                  <option value="">Select Level</option>
                                  {level?.map((item: any) => (
                                    <option key={item.id} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Form.Select>
                                {selectedOption?.length > 0 && (
                                  <>
                                    <AiOutlineCloseCircle
                                      type="reset"
                                      className="resetbtn"
                                      onClick={() => {
                                        setSelectedOption([]);
                                      }}
                                    >
                                      close
                                    </AiOutlineCloseCircle>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row  className="mt-4 d-flex  flex-col flex-lg-row  space-around w-100">
                            <Col lg={6} >
                              <Form.Group className=" CloseBtn">
                                <Form.Select
                                  id="dropdown-days"
                                  title="Select days of the week"
                                  name="daysOfWeek"
                                  value={values.daysOfWeek}
                                  onChange={(e: any) => {
                                    setFieldValue("daysOfWeek", e.target.value);
                                    setSearchValue({
                                      ...searchValue,
                                      fieldValue: e?.target?.value,
                                    });
                                  }}
                                >
                                  <option value="">Select Days</option>
                                  <option value="0">All days(Mon-Fri)</option>
                                  <option value="1">Monday</option>
                                  <option value="2">Tuesday</option>
                                  <option value="3">Wednesday</option>
                                  <option value="4">Thursday</option>
                                  <option value="5">Friday</option>
                                  <option value="6">Saturday</option>
                                  <option value="7">Sunday</option>
                                </Form.Select>
                                {values.daysOfWeek?.length > 0 && (
                                  <>
                                    <AiOutlineCloseCircle
                                      type="reset"
                                      className="resetbtn"
                                      onClick={() => {
                                        setFieldValue("daysOfWeek", '');

                                      }}
                                    >
                                      close
                                    </AiOutlineCloseCircle>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="mt-4 mt-lg-0">
                              <Form.Group className="fCloseBtn">
                                <Form.Select
                                  id="dropdown-Time"
                                  title="Select Timings"
                                  name="selectTiming"
                                  value={values.selectTiming}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "selectTiming",
                                      e.target.value
                                    );
                                    setClassName(true);
                                    setSearchValue({
                                      ...searchValue,
                                      selectTiming: e?.target?.value,
                                    });
                                  }}
                                >
                                  <option value="">Select Timing</option>
                                  <option value="0">6AM to 9AM</option>
                                  <option value="1">9AM to 1AM</option>
                                  <option value="2">2PM to 6PM</option>
                                  <option value="3">6PM to 9PM</option>
                                </Form.Select>
                                {values.selectTiming?.length > 0 && (
                                  <>
                                    <AiOutlineCloseCircle
                                      type="reset"
                                      className="resetbtn"
                                      onClick={() => {
                                        setFieldValue("selectTiming", '');

                                      }}
                                    >
                                      close
                                    </AiOutlineCloseCircle>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row  className="mt-4 d-flex  flex-col flex-lg-row  space-around w-100">
                            <Col lg={6}>
                              <Form.Group className="form-group btn-wrap">
                                <Button
                                  type="reset"
                                  variant="secondary"
                                  // disabled={citysearch===""||citysearch.length === 0}
                                  onClick={() => {
                                    resetForm();
                                    setLevel([]);
                                    setSearchTerm("");
                                    setCitysearch([]);
                                    setAreasearch([]);
                                    setSubjectsearch("");
                                    handleClearAutoComplete();
                                  }}
                                >
                                  Clear
                                </Button>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group btn-wrap">
                                <Button
                                  type="submit"
                                  // disabled={areAllFieldsEmpty(values, citysearch, areasearch, subjectSearch)}

                                  variant={
                                    isValidInfo(values)
                                      ? "secondary active"
                                      : "secondary"
                                  }
                                >
                                  Search
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </form>
                      </>
                    )}
                  </Formik> */}
                </SearchBox>
              </HomeBannerLeft>
              <HomeBannerRight>
                <Carousel indicators={false} interval={3000} controls={false}>
                  <Carousel.Item>
                    <BannerTeacher>
                      <img src={Teacher} alt="Teacher" />
                    </BannerTeacher>
                    <TeacherMessage>
                      Partner in your <span>CAREER</span> transformation
                    </TeacherMessage>
                  </Carousel.Item>
                  <Carousel.Item>
                    <BannerTeacher>
                      <img src={NewTeacher} alt="Teacher" />
                    </BannerTeacher>
                    <TeacherMessage>
                      Enriching your <span>STUDENT</span> journey!
                    </TeacherMessage>
                  </Carousel.Item>
                </Carousel>
              </HomeBannerRight>
            </HomeBannerWrap>
          </Container>
        </HomeBanner>
        <PopularTutionSubjects />
        <Testimonial />
        <ReviewSection />
        <FaqSectionList />
      </Content>
      <LayoutFooter className="home-layout-footer">
        <Footer />
      </LayoutFooter>
    </Layout>
  );
};
export default Home;
