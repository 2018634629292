import React from "react";
import { Tabs, TabsProps } from "antd";

import "./styles.scss";


const CustomTabs: React.FC<TabsProps> = (props) => {
  return (
    <div className="tm-custom-tabs">
      <Tabs {...props} />
    </div>
  );
};

export default CustomTabs;