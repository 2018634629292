import React, { useState, ChangeEvent, useMemo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { dayjs } from "../../../utils/lib";
import { Select, Input, TimePicker, DatePicker, Card, Button, Tooltip, message, Popconfirm } from 'antd';
import type { DatePickerProps } from "antd";
import { AppstoreOutlined, CheckOutlined, CloseOutlined, FormOutlined, CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';

import { dayOptions } from '../../../utils/constants';

import { postBatchesData } from '../../../api/create';
import { updateBatches, updateBatchStatus } from '../../../api/update';
import { STATUS, INPUT_TYPE } from "./types";

import {
	BatchRow,
	BatchFieldWrapper,
	FieldLabel,
	BatchActionsWrapper,
	MultiViewItem,
} from "./styledViews";


const TimeFormat = "HH:mm";
const DateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSSSSS";

const initialValues = {
	subject: "",
	level: "",
	location: "",
	timing: "09:00 - 10:00",
	days: [],
	available_seats: "",
	seats_occupied: "",
  start_date: "",
  end_date: "",
};

const Batch: React.FC<any> = ({ data, teacherId, subjects, levels, locations, refreshBatches, onCancelCreate }) => {
  const navigate = useNavigate();

	const isCreate = _.isEmpty(data);
	const isDraft = data.status === STATUS.draft;
	const [isEdit, setEdit] = useState<boolean>(isCreate);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formData, setFormData] = useState<any>(isCreate ? _.clone(initialValues) : _.clone(data));

	const handleFieldChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		const newFormData = _.clone(formData);
		newFormData[name] = value;
		setFormData(newFormData);
	};

	const handleSubmit = async () => {
		let error = null;
		if (!formData.subject) error = "Subject is required";
		else if (!formData.level) error = "Level is required";
		else if (!formData.location) error = "Location is required";
    else if (!formData.start_date) error = "Start Date is required";
    else if (!formData.end_date) error = "End Date is required";
    else if (dayjs(formData.start_date) >= dayjs(formData.end_date)) error = "End Date must be after Start Date";
		else if (!formData.timing) error = "Timing is required";
		else if (_.isEmpty(formData.days)) error = "Atleast one day is required";
		else if (!String(formData.available_seats)) error = "Total Seats is required";
		else if (isNaN(formData.available_seats)) error = "Total Seats must be a number";
		else if (parseInt(formData.available_seats) < 1) error = "Total Seats must be 1 or above";
		else if (!String(formData.seats_occupied)) error = "Occupied Seats is required";
		else if (isNaN(formData.seats_occupied)) error = "Occupied Seats must be a number";
		else if (parseInt(formData.seats_occupied) > parseInt(formData.available_seats)) error = "Occupied Seats cannot be more than Total Seats";

		if (error) {
			message.error(error);
			return;
		}
		setIsLoading(true);

		const payload = _.assign({}, formData, {
			teacher_id: teacherId,
			available_seats: parseInt(formData.available_seats),
			seats_occupied: parseInt(formData.seats_occupied),
		});

		const apiFn = isCreate ? postBatchesData : updateBatches;
		const res = await apiFn(payload, formData.id);
		if (!res.success) {
			message.error("Failed to save Batch");
			setIsLoading(false);
			return;
		}
		message.success("Batch saved successfully");
		refreshBatches();
	};

  const handleUpdateStatus = async (status: STATUS) => {
    setIsLoading(true);

    const res = await updateBatchStatus({ status }, data.id);
    if (!res.success) {
			message.error("Failed to update status");
			setIsLoading(false);
			return;
		}
		message.success("Status updated successfully");
		refreshBatches();
  };

	return (
		<BatchRow className={isDraft ? "draft" : ""}>
			{!isCreate && <BatchField readOnly bold label="Batch ID" value={data.batch_id} />}
			<BatchField readOnly={!isCreate && !isDraft} isEdit={isEdit} inputType={INPUT_TYPE.select} label="Subject" name="subject" value={formData.subject} onChange={handleFieldChange} options={subjects} />
			<BatchField readOnly={!isCreate && !isDraft} isEdit={isEdit} inputType={INPUT_TYPE.select} label="Level" name="level" value={formData.level} onChange={handleFieldChange} options={levels} />
			<BatchField readOnly={!isCreate && !isDraft} isEdit={isEdit} inputType={INPUT_TYPE.select} label="Location" name="location" value={formData.location} onChange={handleFieldChange} options={locations} />
      <BatchField readOnly={!isCreate && !isDraft} isEdit={isEdit} inputType={INPUT_TYPE.date} label="Start Date" name="start_date" value={formData.start_date} onChange={handleFieldChange} />
      <BatchField isEdit={isEdit} inputType={INPUT_TYPE.date} isEod label="End Date" name="end_date" value={formData.end_date} onChange={handleFieldChange} />
			<BatchField isEdit={isEdit} inputType={INPUT_TYPE.timeRange} label="Timing" name="timing" value={formData.timing} onChange={handleFieldChange} />
			<BatchField isEdit={isEdit} inputType={INPUT_TYPE.multiSelect} label="Days" name="days" value={formData.days} onChange={handleFieldChange} options={dayOptions} />
			<BatchField isEdit={isEdit} inputType={INPUT_TYPE.text} type="number" label="Total Seats" name="available_seats" value={formData.available_seats} onChange={handleFieldChange} />
			<BatchField isEdit={isEdit} inputType={INPUT_TYPE.text} type="number" label="Occupied Seats" name="seats_occupied" value={formData.seats_occupied} onChange={handleFieldChange} />

			<BatchActions
				isEdit={isEdit}
				isLoading={isLoading}
        status={data.status}
				onClickEdit={() => setEdit(true)}
				onClickSave={handleSubmit}
        onClickCancelEdit={() => isCreate ? onCancelCreate() : setEdit(false)}
        onClickUpdateStatus={handleUpdateStatus}
				onClickStudyMaterials={() => navigate(`/batches/${data.batch_id}/study-materials`)}
			/>
		</BatchRow>
	);
};

const BatchField: React.FC<any> = ({ readOnly, isEdit, inputType, bold, type, isEod, label, name, value, options, onChange }) => {
	const handleChangeSelect = (val: string|number[]) => onChange({ target: { name, value: val } });

	return (
		<BatchFieldWrapper
			style={(isEdit && (
				(inputType === INPUT_TYPE.multiSelect && { minWidth: 250 }) ||
        (inputType === INPUT_TYPE.text && { maxWidth: 130 })
			)) || {}}
		>
			<FieldLabel>{label}</FieldLabel>

			{!isEdit ? (
				<Card style={{ fontWeight: bold ? "bold" : "normal" }}>
					{
            (inputType === INPUT_TYPE.multiSelect && <MultiSelectView values={value} options={options} />) ||
            (inputType === INPUT_TYPE.date && (!value ? "N/A" : dayjs.utc(value).local().format("YYYY-MM-DD"))) ||
            value
          }
				</Card>
			) : (
				(inputType === INPUT_TYPE.select && <Select disabled={readOnly} size="large" style={{ width: "100%" }} value={value} options={options} onChange={handleChangeSelect} />) ||
				(inputType === INPUT_TYPE.text && <Input disabled={readOnly} size="large" style={{ width: "100%" }} type={type} name={name} value={value} onChange={onChange} />) ||
				(inputType === INPUT_TYPE.timeRange && <TimeRangeInput readOnly={readOnly} name={name} value={value} onChange={onChange} />) ||
				(inputType === INPUT_TYPE.multiSelect && <Select mode="multiple" allowClear disabled={readOnly} size="large" style={{ width: "100%" }} value={value} options={options} onChange={handleChangeSelect} />) ||
        (inputType === INPUT_TYPE.date && <DateInput readOnly={readOnly} isEod={isEod} name={name} value={value} onChange={onChange} />)
			)}
		</BatchFieldWrapper>
	);
};

const MultiSelectView: React.FC<{ values: any[], options: any[] }> = ({ values, options }) => {
	const items = useMemo(() => {
		return _.map(values, value => {
			const opt = options.find(o => o.value === value);
			if (!opt) return "N/A";
			return opt.label;
		});
	}, [values, options]);

	return (
		<>
			{_.map(items, (label, idx) => (
				<MultiViewItem key={idx}>{label}</MultiViewItem>
			))}
		</>
	);
};

const TimeRangeInput: React.FC<any> = ({ readOnly, name, value, onChange }) => {
	let rangeValues = undefined;
	if (value && value.includes("-") && value.length > 3) rangeValues = value.split(" - ");

	const handleChange = (__: any, strings: string[]) => {
		onChange({ target: {
			name, value: !strings[0] ? "" : `${strings[0]} - ${strings[1]}`
		} });
	};

	return (
		<TimePicker.RangePicker
			disabled={readOnly}
			size="large"
			format={TimeFormat}
			minuteStep={15}
			style={{ width: "100%" }}
			value={rangeValues && [dayjs(rangeValues[0], TimeFormat), dayjs(rangeValues[1], TimeFormat)]}
			onChange={handleChange}
		/>
	);
};

const DateInput: React.FC<any> = ({ readOnly, isEod, name, value, onChange }) => {
  const handleChange: DatePickerProps["onChange"] = (date) => {
    const xValue = !date ? "" : (isEod ? date.endOf("day") : date).utc().format(DateTimeFormat);
    onChange({ target: {
      name, value: xValue
    } });
  };

  return (
    <DatePicker
      disabled={readOnly}
      size="large"
      style={{ width: "100%" }}
      value={!value ? undefined : dayjs.utc(value).local()}
      onChange={handleChange}
    />
  );
};

const BatchActions: React.FC<any> = ({ isEdit, isLoading, status, onClickEdit, onClickSave, onClickCancelEdit, onClickUpdateStatus, onClickStudyMaterials }) => {
	return (
		<BatchActionsWrapper>
			{status !== STATUS.closed && (!isEdit ? (
				<Tooltip title="Edit">
					<Button shape="circle" icon={<FormOutlined />} onClick={onClickEdit} />
				</Tooltip>
			) : (
				<>
          <Tooltip title="Save">
            <Button shape="circle" icon={<CheckOutlined />} onClick={onClickSave} loading={isLoading} />
          </Tooltip>
          {!isLoading && <Tooltip title="Cancel">
            <Button shape="circle" icon={<CloseOutlined />} onClick={onClickCancelEdit} />
          </Tooltip>}
        </>
			))}

			{!isEdit && <>
        {status === STATUS.draft && <Tooltip title="Publish">
          <Popconfirm
            title="Publish Batch"
            description="Are you sure you want to publish this batch?"
            okText="Yes" cancelText="No"
            onConfirm={() => onClickUpdateStatus(STATUS.published)}
          >
            <Button shape="circle" icon={<CheckSquareOutlined />} loading={isLoading} />
          </Popconfirm>
        </Tooltip>}
        {status === STATUS.published && <Tooltip title="Close Batch">
          <Popconfirm
            title="Close Batch"
            description="Are you sure you want to close enrollment for this batch?"
            okText="Yes" cancelText="No"
            onConfirm={() => onClickUpdateStatus(STATUS.closed)}
          >
            <Button shape="circle" icon={<CloseSquareOutlined />} loading={isLoading} />
          </Popconfirm>
        </Tooltip>}
        <Tooltip title="Study Materials">
          <Button shape="circle" icon={<AppstoreOutlined />} onClick={onClickStudyMaterials} />
        </Tooltip>
      </>}
		</BatchActionsWrapper>
	);
};

export default Batch;