import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { FaPhoneAlt, FaUserGraduate } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { TbCurrencyRupee } from "react-icons/tb";
import { TfiWorld } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, message } from 'antd';
import * as Yup from "yup";
import { getTeacherProfileById } from "../../../api/list";
import StarRating from "../../../components/TuorPanel/Accordion/starRatings";
import RoundedButton from "../../../components/TuorPanel/Button";
import DynamicImageUpload from "../../../components/TuorPanel/ImageInput";
import StyledInput from "../../../components/TuorPanel/Input/input";
import Modal from "../../../components/TuorPanel/Modal/modal";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import FileUploader from "../../../components/FileUploader/Input";
import { hostConfig } from "../../../config";
import {
  GetTeacherProfileById,
  updatedProfile,
} from "../../../redux/action/profileAction";
import { GetReviewList } from "../../../redux/action/reviewAction";
import { openInNewTab } from "../../../utils/helper";
import './index.scss';

import calculateOverallReviewCount from "../../../utils/reviewFormat"

import ProfileForm from "./ProfileForm";
import UserImage from "../../../assets/images/user-placeholder-small.png";


const Profile = () => {
  const [imageData, setImageData] = useState();
  const [base64, setBase64] = useState("");
  const handleImageSelect = (imageData, base64Image) => {
    setImageData(imageData);
    setBase64(base64Image);
    console.log("Selected image data:", imageData);
  };
  const dispatch = useDispatch();
  const [teacherID, setTeacherID] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [averageRating, setAverageRating] = useState();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    mobile_number: "",
    address: "",
    email_id: "",
    website: "",
    discription: "",
    education: "",
    is_profile: "",
    availability: "",
    profile_filename: "",
    is_active: true,
    first_name: "",
    day: "",
    tuition_name: "",
    last_name: "",
    fee_per_month: "",
    fee_frequency:"",
    city: "",
    is_active: true,
    location: "",
    area: "",
  });
  const cookies = new Cookies();

  const initialUserData = async () => {
    const userData = cookies.get("TeacherloggedUser");
    const TeacherID = userData;
    console.log(teacherID, "ID HERE");
    const id = TeacherID?.id;
    console.log(id, "id here");
    const result = await dispatch(GetTeacherProfileById(id));
    await dispatch(GetReviewList(id, null)).then((res) =>
      setReviewCount(res?.total_records)
    );
    setFormData(result?.records);
    setTeacherID(id);
    setLoading(false);
  };
  console.log(imageData, "profiledata");
  useEffect(() => {
    setLoading(true);
    initialUserData();
    const rating = localStorage.getItem("Average Rating");
    const roundedRating = Math.round(parseFloat(rating));
    setAverageRating(roundedRating);
  }, [averageRating]);

  const [modal, setModal] = useState(false);
  const handleOpen = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ["teacher_user_id"]: teacherID?.id,
      ["location"]: formData?.address,
      ["area"]: "",
      // ["is_profile"]: true,
      ["profile_filename"]: imageData ? imageData : "", // Update profile_filename
    }));
  };

  const handleEditCLose = () => {
    setModal(false);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log(e, "jdnfjfbjdfnj");
    const updatedFormData = {
      ...e,
      ["area"]: "",
      ["day"]: "0",
      ["body"]: "",
      ["location"]: "chennai",
      ["profile_filename"]: imageData ? imageData : "", // Update profile_filename
    };
    if (base64 != "") {
      var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
      updatedFormData["base64_image"] = strImage;
    }
    const response = await dispatch(updatedProfile(updatedFormData, teacherID));
    console.log("Update successfull:", response);
    if (response) {
      const user = await dispatch(GetTeacherProfileById(teacherID)).catch((error) => {
        console.error("Error fetching user data:", error);
        return null;
      });
      if (user && user.records) {
        // Update the local storage with the updated user data
        localStorage.setItem("loggedUsers", JSON.stringify(user.records));
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      setFormData(response.records); // Assuming the API response contains the updated data
      setModal(false);
    }
    initialUserData();
  };
  console.log(formData, "forms");

  const validationSchema = Yup.object().shape({
    // day: Yup.string().required("Day is required"),
    fee_per_month: Yup.string().required("Fee is required"),
    city: Yup.string().required("City is required"),
    availability: Yup.string().required("Availability is required"),
    mobile_number: Yup.string()
      .matches(
        /^[6-9][0-9]{9}$/,
        "Mobile number must start with a digit from 6 to 9 and be 10 digits long"
      )
      .required("Mobile number is required"),
    email_id: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // website: Yup.string().required("Website is required"),
    address: Yup.string().required("Address is required"),
    education: Yup.string().required("Education is required"),
    tuition_name: Yup.string().required("Tution Name is required"),
    discription: Yup.string().required("Description is required"),
  });
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const handleUpdateSuccess = async () => {
    setModal(false);
    const res = await getTeacherProfileById(teacherID);
    if (!res.success) {
      message.error("Failed to fetch user profile.");
      return;
    }
    localStorage.setItem("loggedUsers", JSON.stringify(res.records));
    window.location.reload();
  };

  return (
    <div className="profile-page">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="prime-text fw-bolder mb-0" style={{ fontSize: '20px' }}>My Profile</h6>
          <RoundedButton variant="primary" onClick={handleOpen}>
            Edit Profile
          </RoundedButton>
        </div>
        {loading ? (
          <CustomPreloader />
        ) : (
          <Row gutter={[0, 16]} className="profile-page-details">
            <Col xs={24} sm={24} md={24} lg={8}>
              {/* <StyledLeftSide> */}
              <div className="profile-page-details-left">
                <AvatarImage
                  src={formData?.profile_filename || UserImage}
                  alt=""
                />
                <h5 className="my-3">
                  {formData?.first_name} {formData?.last_name}
                </h5>
                <p className="text-center" >
                  Tuition Address - {formData?.address}
                </p>
                <div className="profile-page-details-left-rating">
                  <div className="me-2">
                    <StarRating
                      rating={calculateOverallReviewCount(formData?.overall_review_status?.total_start_count,formData?.overall_review_status?.student_count)}
                      totalStars={5}
                      starSize={17}
                    />
                  </div>
                  <div className="ms-2">
                    <p style={{  fontSize: '12px', margin: '0px'}}>
                      {calculateOverallReviewCount(formData?.overall_review_status?.total_start_count,formData?.overall_review_status?.student_count)} / 5&nbsp; | &nbsp;
                      <Link to="/reviews" style={{ color: '#000000', fontSize: '12px'}}>
                        {formData?.overall_review_status?.student_count} &nbsp;
                        <span>Reviews</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              {/* <StyledRightSide> */}
              <div className="profile-page-details-right">
                <p style={{ color: "#000000" }}>{formData?.discription}</p>
                <hr />
                <div className="grid-wrapper-profile">
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <FaUserGraduate />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Education</Title>
                      <Subtitle>{formData?.education}</Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <IoCheckmarkCircle />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Availablity</Title>
                      <Subtitle>{formData?.availability}</Subtitle>
                    </TextWrapper>
                  </section>
                 {formData?.fee_per_month===null? <></>:<section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <TbCurrencyRupee />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Average Fee Per {formData?.fee_frequency===null?"":formData?.fee_frequency}</Title>
                      <Subtitle>{formData?.fee_per_month}</Subtitle>
                    </TextWrapper>
                  </section>}
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <MdLocationOn />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Location</Title>
                      <Subtitle>{formData?.address}</Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <FaPhoneAlt />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Phone</Title>
                      <Subtitle>{formData?.mobile_number}</Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <GrMail />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Email</Title>
                      <Subtitle>{formData?.email_id} </Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center">
                    <IconWrapper>
                      <IconBackground>
                        <TfiWorld />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Website</Title>
                      <Subtitle>{formData?.website}</Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center" style={{ overflow: "hidden" }}>
                    <IconWrapper>
                      <IconBackground>
                        <TfiWorld />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Intro Video</Title>
                      <Subtitle>{formData?.intro_video_url}</Subtitle>
                    </TextWrapper>
                  </section>
                  <section className="d-flex align-items-center" style={{ overflow: "hidden" }}>
                    <IconWrapper>
                      <IconBackground>
                        <TfiWorld />
                      </IconBackground>
                    </IconWrapper>
                    <TextWrapper>
                      <Title>Intro Video Thumbnail</Title>
                      <Subtitle>{formData?.intro_thumb_url}</Subtitle>
                    </TextWrapper>
                  </section>
                </div>
              {/* </StyledRightSide> */}
              </div>
            </Col>
          </Row>
        )}
        {modal ? (
          <ProfileForm onHide={handleEditCLose} onSuccess={handleUpdateSuccess} />
      //     <Modal
      //       title="Edit My Profile"
      //       hide={handleEditCLose}
      //       isShowing={modal}
      //       width="600px"
      //     >
      //       <Formik
      //         initialValues={{
      //           day: "",
      //           fee_per_month: formData?.fee_per_month ?? "",
      //           fee_frequency:formData?.fee_frequency ?? "Month",
      //           city: formData?.city ?? "",
      //           availability: formData?.availability ?? "",
      //           mobile_number: formData?.mobile_number ?? "",
      //           email_id: formData?.email_id ?? "",
      //           website: formData?.website ?? "",
      //           address: formData?.address ?? "",
      //           education: formData?.education ?? "",
      //           tuition_name: formData?.tuition_name ?? "",
      //           discription: formData?.discription ?? "",
      //           location: formData?.address ?? "",
      //           intro_video_url: formData?.intro_video_url ?? "",
      //           intro_thumb_url: formData?.intro_thumb_url ?? "",
      //           // is_profile: true,
      //         }}
      //         validationSchema={validationSchema}
      //         onSubmit={handleSubmit}
      //       >
      //         {({
      //           values,
      //           errors,
      //           touched,
      //           handleChange,
      //           handleBlur,
      //           handleSubmit,
      //           isSubmitting,
      //           setFieldValue,
      //           dirty,
      //         }) => (
      //           <>
      //             <form>
      //               <div>
      //                 <div className="row p-3 pb-0">
      //                   <DynamicImageUpload
      //                     url={
      //                       formData?.is_profile
      //                         ? `${hostConfig.S3_BUCKET}${formData?.profile_filename}?lastdate=` +
      //                           Date()
      //                         : null
      //                     }
      //                     onImageSelect={handleImageSelect}
      //                     name="profile_filename"
      //                   />
      //                   <div className="col-6">
      //                     <StyledInput
      //                       type="select"
      //                       options={[
      //                         "10.00 AM to 11.00 AM",
      //                         "12.00 AM to 01.00 PM",
      //                         "02.00 PM to 03.00PM",
      //                       ]}
      //                       placeholder="Select option"
      //                       name="day"
      //                       onChange={handleChange}
      //                     />
      //                     <div className="error">
      //                       {touched.day && errors.day && <p>{errors.day}</p>}
      //                     </div>
      //                   </div>
      //                   <div className="col-6">
      // <div style={{ display: 'flex' }}>
      //   <div style={{  marginLeft: '10px', display: 'flex' }}>
      //     <div style={{ flex: '1' }}>
      //       <StyledInput
      //         type="text"
      //         placeholder="Average Fee "
      //         name="fee_per_month"
      //         value={values?.fee_per_month}
      //         onChange={handleChange}
      //         style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }} // Common styles
      //       />
      //     </div>
      //     <div style={{ flex: '1' }}>
      //     <StyledInput
      //                       type="select"
      //                       options={["Month", "Batch","Course"]}
      //                       placeholder="Select fee frequency"
      //                       name="fee_frequency"
      //                       value={values?.fee_frequency}
      //                       onChange={handleChange}
      //                       style={{ width: '100%',padding: '10px', boxSizing: 'border-box' }}
      //                     />
      //       {/* <select
      //         name="fee_frequency"
      //         value={values?.fee_frequency}
      //         onChange={handleChange}
      //         style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }} // Common styles
      //       >
      //         <option value="month">Month</option>
      //         <option value="batch">Batch</option>
      //         <option value="course">Course</option>
      //       </select> */}
      //     </div>
      //   </div>
      
      //   <div className="error">
      //     {touched.fee_per_month && errors.fee_per_month && (
      //       <p>{errors.fee_per_month}</p>
      //     )}
      //     {/* Add error handling for the dropdown if needed */}
      //   </div>
      // </div>
   
      //                     {/* <StyledInput
      //                       type="text"
      //                       placeholder="Average Fee for Month"
      //                       width="100%"
      //                       name="fee_per_month"
      //                       value={values?.fee_per_month}
      //                       onChange={handleChange}
      //                     />
      //                     <div className="error">
      //                       {touched.fee_per_month && errors.fee_per_month && (
      //                         <p>{errors.fee_per_month}</p>
      //                       )}
      //                     </div> */}
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       type="text"
      //                       width="100%"
      //                       placeholder="city"
      //                       name="city"
      //                       value={values?.city}
      //                       onChange={handleChange}
      //                     />
      //                     <div className="error">
      //                       {touched.city && errors.city && (
      //                         <p>{errors.city}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       type="select"
      //                       options={["Available", "Unavailable"]}
      //                       placeholder="Select option"
      //                       name="availability"
      //                       value={values?.availability}
      //                       onChange={handleChange}
      //                     />
      //                     <div className="error">
      //                       {touched.availability && errors.availability && (
      //                         <p>{errors.availability}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="number"
      //                       placeholder="Mobile Number"
      //                       name="mobile_number"
      //                       value={values?.mobile_number}
      //                       disabled
      //                     />
      //                     <div className="error">
      //                       {touched.mobile_number && errors.mobile_number && (
      //                         <p>{errors.mobile_number}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="email"
      //                       placeholder="Email ID"
      //                       disabled
      //                       name="email_id"
      //                       value={values?.email_id}
      //                     />
      //                     <div className="error">
      //                       {touched.email_id && errors.email_id && (
      //                         <p>{errors.email_id}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="url"
      //                       name="website"
      //                       placeholder="Website"
      //                       onChange={handleChange}
      //                       value={values?.website}
      //                     />
      //                     <div className="error">
      //                       {touched.website && errors.website && (
      //                         <p>{errors.website}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="text"
      //                       name="address"
      //                       placeholder="Address"
      //                       onChange={handleChange}
      //                       value={values?.address}
      //                     />
      //                     <div className="error">
      //                       {touched.address && errors.address && (
      //                         <p>{errors.address}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="text"
      //                       placeholder="Education"
      //                       onChange={handleChange}
      //                       value={values?.education}
      //                       name="education"
      //                     />
      //                     <div className="error">
      //                       {touched.education && errors.education && (
      //                         <p>{errors.education}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-6 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="text"
      //                       placeholder="Tuition Name"
      //                       name="tuition_name"
      //                       onChange={handleChange}
      //                       value={values?.tuition_name}
      //                     />
      //                     <div className="error">
      //                       {touched.tuition_name && errors.tuition_name && (
      //                         <p>{errors.tuition_name}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-12 mt-3">
      //                     <StyledInput
      //                       width="100%"
      //                       type="text"
      //                       placeholder="Description"
      //                       name="discription"
      //                       onChange={handleChange}
      //                       value={values?.discription}
      //                     />
      //                     <div className="error">
      //                       {touched.discription && errors.discription && (
      //                         <p>{errors.discription}</p>
      //                       )}
      //                     </div>
      //                   </div>
      //                   <div className="col-12 mt-3">
      //                     <FileUploader
      //                       bucket="teacher_profile"
      //                       name="intro_video_url"
      //                       value={values?.intro_video_url}
      //                       label="Intro Video"
      //                       onChange={handleChange}
      //                       onView={(url) => openInNewTab(url)}
      //                       fileTypes={["video/mp4"]}
      //                       maxFileSize={25}
      //                     />
      //                   </div>
      //                   <div className="col-12 mt-3">
      //                     <FileUploader
      //                       bucket="teacher_profile"
      //                       name="intro_thumb_url"
      //                       value={values?.intro_thumb_url}
      //                       label="Intro Video Thumbnail"
      //                       onChange={handleChange}
      //                       onView={(url) => openInNewTab(url)}
      //                       fileTypes={["image/jpeg", "image/png"]}
      //                       maxFileSize={0.5}
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="my-3 text-left d-flex justify-content-center">
      //                   <RoundedButton variant="secondery">
      //                     Cancel
      //                   </RoundedButton>
      //                   <RoundedButton
      //                     variant="primary"
      //                     type="button"
      //                     onClick={handleSubmit}
      //                   >
      //                     Save
      //                   </RoundedButton>
      //                 </div>
      //               </div>
      //             </form>
      //           </>
      //         )}
      //       </Formik>
      //     </Modal>
        ) : null}
    </div>
  );
};

export default Profile;
const IconBackground = styled.div`
  background-color: #940e34; /* Background color for the icon */
  border-radius: 50%; /* Rounded shape */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 30px; /* Adjust the size for uniformity */
  height: 30px; /* Adjust the size for uniformity */
  color: #fff;
`;
const AvatarImage = styled.img`
  width: 80px;
  height: 80px;
  border: 3px solid #fff; /* White border with 2px width */
  border-radius: 50%; /* Rounded shape */
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  margin-right: 10px;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #940e34;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 14px;
  color: #000000;
  white-space: nowrap; /* Add this property to prevent text from breaking */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; 
  word-wrap: break-word;
`;
