export const dayOptions = [
  { label: "All days (Mon - Fri)", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];

export const timeSlots = [
  { label: "6AM to 9AM", value: 0 },
  { label: "9AM to 1PM", value: 1 },
  { label: "2PM to 6PM", value: 2 },
  { label: "6PM to 9PM", value: 3 },
];