import styled from "styled-components";
import { Color } from "../../../utils/styleUtils";


export const MainContainer = styled.div`
  margin-left: 300px;
  padding: 30px;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const PageTitle = styled.h4`
  position: relative;
  margin-top: 20px;
  font-weight: bold;
  color: ${Color.primary};
`;

export const AddBatchButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  .ant-btn {
    background: ${Color.primary};
    &:hover {
      background: ${Color.primaryLight} !important;
    }
    &:active {
      background: ${Color.secondary} !important;
    }
  }
`;

export const Divider = styled.div`
  height: 4px;
  margin: 20px 0;
  background-color: ${Color.accent};
`;

export const ListContainer = styled.div`
  overflow-y: auto;
`;

export const BatchRow = styled.div`
  min-width: 1800px;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${Color.bgPink};
  display: flex;
  gap: 20px;

  &.draft {
    background-color: ${Color.bgLight};
  }
`;

export const BatchFieldWrapper = styled.div`
  flex-grow: 1;
  min-width: 100px;
  max-width: 205px;
  
  .ant-card-body {
    padding: 6px 11px;
    font-size: 16px;
  }
`;

export const FieldLabel = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  color: ${Color.secondary};
`;

export const BatchActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;

  .ant-btn:hover {
    border-color: ${Color.primary} !important;
    color: ${Color.secondary} !important;
  }
`;

export const MultiViewItem = styled.span`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 0 5px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.08);

  &:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
`;