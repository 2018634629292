const faqTextData = {
  heading: "Frequently asked questions",
  faqContent: "Tution Mitra is committed to help you in your tuition finding journey. Please connect with customer service if these questions do not serve your purpose.",

  accordionContent: [
    {
      question: "Why should you choose TuitionMitra?",
      answer: "TuitionMitra is a one stop solution for all your tuitions needs. It enables you to search for a comprehensive result of the most suitable tuition. The platform includes comprehensive search criteria such as city,area, subject, level, days of the week and timings. Students can watch a demo of the tuition which helps you to make the right choice. They can book the classes in advance by  paying INR 200 on the platform . In addition to tuition, TuitionMitra platform provides you to find rental and accommodations catered for students.",
    },
    {
      question: "Does it cost to register with TuitionMitra?",
      answer: "No, it doesn't cost you to register with TuitionMitra. TuitionMitra is an absolutely free platform for the students.",
    },
    {
      question: "How can you search for tuition on TuitionMitra?",
      answer: "TuiitionMitra gives flexibility to the students to search for a teacher. On the home page enter your search criteria such as city,area,subject,level days of the week and timing and click on the search button. The platform will display the results of your search. You can then select the most relevant tuition for you.",
    },
    {
      question: "How can you book a demo class?",
      answer: "To book a demo class on the home page click on the book a demo tab, enter your search criteria for the demo class that you want to book and click submit. TuitionMitra platform will display the list of classes where the demo class is available as per your search. Click attend a demo class, then select the date and click on Book. You will then receive the booking confirmation on your registered email ID.\nAlternatively you can also book a demo class from the home page where you can search the tuition and book the demo from the tuition profile page.",
    },
    {
      question: "How can you confirm your enrollment for the coaching institutes?",
      answer: "Once student selects the teacher, the platform will navigate to the payment platform for you to pay INR 100. Complete your payment process and after confirmation of the payment you will receive email confirmation of your reservation of the seat in your registered email ID. Student can then directly contact the tuition to proceed with further formalities.",
    },
  ],
};

export default faqTextData;
