import React, { type FC, useState } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import HeaderContent from "../Header";

import TuitionMitraLogo from "../../../assets/images/TuitionMitraLogo.svg";
import { Drawer, Layout, Menu } from "antd";
import "./sidebar.scss";

const { Header, Content, Footer } = Layout;
interface MenuItem {
  key: string;
  path: string;
  label: string;
}
const menuItems: MenuItem[] = [
  {
    key: "dashboard",
    path: "/dashboard",
    label: "Dashboard",
  },
  {
    key: "student-info",
    path: "/student-info",
    label: "View Student Information",
  },
  {
    key: "batches",
    path: "/batches",
    label: "Manage Batches",
  },
  {
    key: "post",
    path: "/post",
    label: "Post Your Ad",
  },
  {
    key: "reviews",
    path: "/reviews",
    label: "My Reviews",
  },
  {
    key: "profile",
    path: "/profile",
    label: "My Profile",
  },
];

interface SidebarProps {
  data: any;
}

const MobileSidebar: FC<SidebarProps> = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openKey, setOpenKey] = useState(["dashboard"]);
  const currentKey = window.location.href.split("/")[3];
  console.log(props.data, "sidebar");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleClearLocalStorage = () => {
    localStorage.clear();

    cookies.remove("TeacherloggedUser", { path: "/" });
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  const location = useLocation();
  const onClose = () => {
    setOpen(false);
  };
  const onClickHandler = (key: any) => {
    setOpenKey([key.key]);
    setOpen(false);
  };
  return (
    <Layout hasSider className="teacher-page-layout-mobile">
      <Drawer
        placement="left"
        open={open}
        onClose={onClose}
        className="teacher-page-layout-drawer"
      >
        <div className="teacher-page-sider">
          <div className="teacher-page-sider-img" onClick={onClose}>
            <img src={TuitionMitraLogo} alt="Tution Mitra" />
          </div>
          <Menu
            className="teacher-page-sider-menu"
            theme="light"
            mode="inline"
            defaultSelectedKeys={[currentKey]}
            onClick={onClickHandler}
            openKeys={openKey}
          >
            {menuItems.map((items) => (
              <Menu.Item key={items.key}>
                <Link to={items.path} />
                {items.label}
              </Menu.Item>
            ))}
            <Menu.Item>
              <div
                className="icon"
                role="button"
                onClick={handleClearLocalStorage}
              >
                Logout
              </div>
            </Menu.Item>
          </Menu>
        </div>
      </Drawer>
      <Header className="teacher-page-header">
         <HeaderContent setOpen={setOpen} data={props.data} />
      </Header>
      <Content className="teacher-page-content">{props.data}</Content>
      {/* <Footer className="teacher-page-footer">footer</Footer> */}
    </Layout>
  );
};

export default MobileSidebar;