import _ from "lodash";

export const formatQueryArrayStringForSearch = (data) => {
    return data.length ?  data.map(e =>  `'${e}'`).join(',') : data
}

export function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

export function formatFileSize(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const threshold = 1024;

  let index = 0;
  while (bytes >= threshold && index < units.length - 1) {
    bytes /= threshold;
    index++;
  }

  return `${bytes.toFixed(2)} ${units[index]}`;
}

export function downloadURI(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const REGEX = {
  numbers: /^\d+$/,
  url: /^https?:\/\/([a-z0-9-]+\.)+[a-z]{2,}(\/[^\s]*)?$/i,
  email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
  phone10d: /^[6-9]\d{9}$/,
  phoneGlobal: /^\+(?:[0-9] ?){6,14}[0-9]$/,
};

export function overrideDefaults(defaults, overrides) {
  if (_.isEmpty(defaults)) return {};
  if (_.isEmpty(overrides)) return _.clone(defaults);

  return Object.keys(defaults).reduce((merged, key) => {
    merged[key] = overrides[key] ?? defaults[key];
    return merged;
  }, {});
}

export function nullifyEmptyValues(object) {
  if (_.isEmpty(object)) return {};

  return Object.keys(object).reduce((values, key) => {
    const val = object[key];
    values[key] = (_.isNumber(val) && !isNaN(val)) || _.isBoolean(val) || !_.isEmpty(val) ? val : null;
    return values;
  }, {});
}

export const convertRailwayTimeToStandardTime = (railwayTime) => {
  const [hours, minutes] = railwayTime.split(':');
  const hoursNum = parseInt(hours, 10);
  const period = hoursNum >= 12 ? 'PM' : 'AM';
  const standardHours = (hoursNum % 12) || 12; // Use 12 for 0 or 12
  return `${standardHours}:${minutes} ${period}`;
}

export const convertRailwayTimeRangeToStandardTimeRange = (railwayTimeRange) => {
  // Split the railway time range into start and end times
  const [startTime, endTime] = railwayTimeRange.split(' - ');

  // Convert the start time to standard time
  const standardStartTime = convertRailwayTimeToStandardTime(startTime);

  // Convert the end time to standard time
  const standardEndTime = convertRailwayTimeToStandardTime(endTime);

  // Combine the standard times back into a time range
  const standardTimeRange = `${standardStartTime} - ${standardEndTime}`;

  return standardTimeRange;
}