import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Button, Input, message, Space, Typography, InputProps } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { SizeType } from "antd/es/config-provider/SizeContext";

import UploaderModal from "./Modal";

import { uploadFile } from "../../api/create";
import { makeId, formatFileSize, REGEX } from "../../utils/helper";

import { FieldContainer } from "./styledViews";

const { Text } = Typography;


type Props = {
    bucket: string
    name?: string
    value: string
    label?: string
    variant?: InputProps["variant"]
    onChange: (event: any, fileSize?: number) => void
    onBlur?: (event: any) => void
    onView?: (value: string) => void
    info?: string
    fileTypes?: string[]
    maxFileSize?: number
    disableUpload?: boolean
    verifyFile?: boolean
    size?: SizeType
    touched?: boolean
    error?: string
}

const FileUploader: React.FC<Props> = ({
    bucket, name, value, label, variant, onChange, onBlur, onView, info, fileTypes, maxFileSize, disableUpload, verifyFile, size, touched, error
}) => {
    const [showUploader, setShowUploader] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const fieldName = name;
    const errorStatus = (touched && error) ? "error" : undefined;
    const fileSize = useRef<number>(0);

    const getFileSizeFromUrlDebounced = useMemo(() => {
        return _.debounce(getFileSizeFromUrl, 1000);
    }, []);
    const [isChecking, setIsChecking] = useState<boolean>(false);

    useEffect(() => {
        if (!verifyFile || fileSize.current || !REGEX.url.test(value)) return;

        setIsChecking(true);
        getFileSizeFromUrlDebounced(value, (res: any) => {
            if (res.error || !res.size) {
                setIsChecking(false);
                return;
            }
            fileSize.current = res.size;
            onChange({ target: { name: fieldName, value } }, res.size);
            setIsChecking(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleInputChange = (event: any) => {
        fileSize.current = 0;
        onChange(event, 0);
    };

    const handleUpload = (file: File) => {
        if (!file) return;

        setShowUploader(false);
        setIsUploading(true);

        const name = getFileName(file.name);

        uploadFile(name, bucket, file).then(res => {
            setIsUploading(false);

            if (!res?.success) {
                message.error("Failed to upload file.");
                return;
            }

            message.success("File uploaded.");
            fileSize.current = file.size;
            onChange({ target: { name: fieldName, value: res.file_url } }, file.size);
        });
    };

    return (
        <FieldContainer>
            <Space.Compact size={size || "large"} style={{ width: "100%" }}>
                <Input
                    variant={variant}
                    status={errorStatus}
                    name={name}
                    value={value}
                    addonBefore={label}
                    disabled={isUploading}
                    onChange={handleInputChange}
                    onBlur={onBlur}
                    spellCheck={false}
                />

                {!disableUpload && <Button
                    type="primary"
                    onClick={() => setShowUploader(true)}
                    loading={isUploading}
                    icon={<UploadOutlined />}
                />}

                {!!onView && !!value && <Button
                    type="primary"
                    onClick={() => onView(value)}
                >
                    View
                </Button>}
            </Space.Compact>

            {verifyFile && !!value && !errorStatus && (
                (isChecking && <Text type="secondary">&nbsp;&nbsp;&nbsp;<LoadingOutlined /></Text>) ||
                (!fileSize.current && <Text type="warning">&nbsp;&nbsp;&nbsp;<CloseCircleOutlined />&nbsp; Unable to verify file</Text>) ||
                (<Text type="success">&nbsp;&nbsp;&nbsp;<CheckCircleOutlined />&nbsp; File size is {formatFileSize(fileSize.current)}</Text>)
            )}
            {errorStatus && <Text type="danger">{error}</Text>}

            {showUploader && <UploaderModal
                show={true}
                onHide={() => setShowUploader(false)}
                onUpload={handleUpload}
                info={info}
                fileTypes={fileTypes}
                maxFileSize={maxFileSize}
            />}
        </FieldContainer>
    );
};

function getFileName(name: string) {
    const split = name.split(".");
    return `${split[0].replaceAll(" ", "")}-${makeId(16)}.${split[split.length - 1]}`;
}

async function getFileSizeFromUrl(url: string, callback: any) {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        const contentLength = response.headers.get('Content-Length');

        if (!contentLength) callback({ error: true });
        else callback({ size: parseInt(contentLength) });
    } catch (error) {
        callback({ error: true });
    }
}

export default FileUploader;