import React, { useEffect, useState, useRef } from 'react';
import _ from "lodash";
import { Cookies } from "react-cookie";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getallSubjects, getBatchesList, getLevels, getLocationList } from '../../../api/list';

import PageSpinner from '../../../components/PageSpinner';
import PageError from '../../../components/PageError';
import Batch from './Batch';

import {
	MainContainer,
	PageTitle,
	AddBatchButton,
	ListContainer,
	Divider,
} from "./styledViews";


const Batches: React.FC = () => {
	const cookies = new Cookies();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string|null>(null);
	const [batches, setBatches] = useState<any>(null);

	const userInfo = useRef<any>(null);
	const subjects = useRef<any>(null);
	const levels = useRef<any>(null);
	const locations = useRef<any>(null);

	const fetchBatches = async () => {
		const params = {
			teacher_id: userInfo.current.id
		};
		const res = await getBatchesList(params);
		if (!res.success) {
			setError("Failed to fetch Batches.");
			setIsLoading(false);
			return false;
		}
		setBatches(res.records);
		return res.records;
	};

	const refreshBatches = async () => {
		setIsLoading(true);
		const res = await fetchBatches();
		if (!res) return;
		setIsLoading(false);
	};

	const fetchSubjects = async () => {
		const res = await getallSubjects();
		if (!res.success) {
			setError("Failed to fetch Subjects.");
			setIsLoading(false);
			return false;
		}
		subjects.current = _.map(res.records, r => ({ label: r.subject, value: r.subject }));
		return res.records;
	};

	const fetchLevels = async () => {
		const res = await getLevels();
		if (!res.success) {
			setError("Failed to fetch Levels.");
			setIsLoading(false);
			return false;
		}
		levels.current = _.map(res.records, r => ({ label: r.level_name, value: r.level_name }));
		return res.records;
	};

	const fetchLocations = async () => {
		const res = await getLocationList();
		if (!res.success) {
			setError("Failed to fetch Locations.");
			setIsLoading(false);
			return false;
		}
		locations.current = _.map(res.records, r => ({ label: r.location, value: r.location }));
		return res.records;
	};

	const initialize = async () => {
		await fetchSubjects() &&
		await fetchLevels() &&
		await fetchLocations() &&
		await fetchBatches() &&
		setIsLoading(false);
	};

	const handleAddBatch = () => {
		const newList = _.map(batches);
		newList.push({});
		setBatches(newList);
	};

	const handleCancelAdd = () => {
		const newList = _.map(batches);
		newList.splice(-1);
		setBatches(newList);
	};

	useEffect(() => {
		userInfo.current = cookies.get("TeacherloggedUser");
		initialize();
	}, []);

  return (
    <MainContainer>
      <PageTitle>
				Batch Listing

				<AddBatchButton>
					<Button type="primary" size="large" icon={<PlusOutlined />} onClick={handleAddBatch}>
						Add Batch
					</Button>
				</AddBatchButton>
			</PageTitle>
			<Divider />

			{(isLoading && <PageSpinner />) ||
			(!!error && <PageError title={error} />) ||
			(
				<ListContainer>
					{_.map(batches, (item, idx) => (
						<Batch
							key={item.id || idx}
							data={item}
							teacherId={userInfo.current.id}
							subjects={subjects.current}
							levels={levels.current}
							locations={locations.current}
							refreshBatches={refreshBatches}
							onCancelCreate={handleCancelAdd}
						/>
					))}
				</ListContainer>
			)}
    </MainContainer>
  );
};

export default Batches;