// ******import types *******//
import {
    LOGIN_USERS_REQUEST,
    LOGIN_USERS_SUCCESS,
    LOGIN_USERS_FALIURE
} from "../types/loginTypes"


const initialState = {
    storeLoginResponse: "",
    error: ""
};

export const loginReducer = (state = initialState, action) => {
    console.log(action, "Im Reducer");
    switch (action.type) {
        case LOGIN_USERS_REQUEST:
            return {
                ...state,
                storeLoginResponse: "true"
            };
        case LOGIN_USERS_SUCCESS:
            return {
                ...state,
                storeLoginResponse: action.payload.responseStatus,
                error: ""
            };
        case LOGIN_USERS_FALIURE:
            return {
                ...state,
                storeLoginResponse: "error",
                error: action.payload,
            };
        default:
            return state;
    }

}