import React, { type FC } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import TeacherHeader from "../../Header/teacherHeader";
import HeaderContent from "../Header";
import TuitionMitraLogo from "../../../assets/images/TuitionMitraLogo.svg";
import { Layout, Menu } from "antd";
import "./sidebar.scss";
import CustomPagination from "../Pagination";

const { Header, Content, Footer, Sider } = Layout;
interface MenuItem {
  key: string;
  path: string;
  label: string;
}
const menuItems: MenuItem[] = [
  {
    key: "dashboard",
    path: "/dashboard",
    label: "Dashboard",
  },
  {
    key: "student-info",
    path: "/student-info",
    label: "View Student Information",
  },
  {
    key: "batches",
    path: "/batches",
    label: "Manage Batches",
  },
  {
    key: "post",
    path: "/post",
    label: "Post Your Ad",
  },
  {
    key: "reviews",
    path: "/reviews",
    label: "My Reviews",
  },
  {
    key: "profile",
    path: "/profile",
    label: "My Profile",
  },
];

interface SidebarProps {
  data: any;
}

const Sidebar: FC<SidebarProps> = (props: any) => {
  const currentKey = window.location.href.split("/")[3];
  console.log(props.data, "sidebar");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleClearLocalStorage = () => {
    localStorage.clear();
    cookies.remove("TeacherloggedUser", { path: "/" });
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  

  return (
    <Layout hasSider className="teacher-page-layout-web">
      <Sider className="teacher-page-sider">
        <div className="teacher-page-sider-img">
          <img src={TuitionMitraLogo} alt="Tution Mitra" />
        </div>
        <Menu
          className="teacher-page-sider-menu"
          theme="light"
          mode="inline"
          defaultSelectedKeys={[currentKey]}
        >          
          {menuItems.map((items) => (
              <Menu.Item key={items.key}>
                <Link to={items.path} />
                {items.label}
              </Menu.Item>
            ))}
            <Menu.Item>
              <div
                className="icon"
                role="button"
                onClick={handleClearLocalStorage}
              >
                Logout
              </div>
            </Menu.Item>
          </Menu>
      </Sider>
      <Layout className="teacher-page-sublayout">
        <Header className="teacher-page-header">
          <HeaderContent setOpen={false} data={props.data} />
        </Header>
        <Content
          className="teacher-page-content"
        >
          {props.data}
        </Content>
      {/* <Footer className='teacher-page-footer'></Footer> */}
      </Layout>
    </Layout>
  );
};

export default Sidebar;
