// Theme Colors
const claret = "#940e34";
const cardinal = "#d2144a";
const lightOrange = "#fee1c7";
const tekhelet = "#3d348b";
const bitterSweet = "#fa7e61";
const lavenderBlush = "#f0e2e6";
const richBlack = "#031927";

export const Color = {
  primary: claret,
  secondary: bitterSweet,
  accent: tekhelet,
  primaryLight: cardinal,
  bgLight: lightOrange,
  bgPink: lavenderBlush,
  bgBlack: richBlack,

  textTitle: richBlack,
  textPrimary: "rgba(0,0,0,0.6)",
  textSecondary: "rgba(0,0,0,0.4)",

  btnPrimary: claret,
  btnPrimaryHover: cardinal,
  btnPrimaryActive: bitterSweet,
};