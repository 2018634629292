export enum STATUS {
  draft = "draft",
  published = "published",
  closed = "closed"
}

export enum INPUT_TYPE {
	select = "select",
	text = "text",
	timeRange = "timeRange",
	multiSelect = "multiSelect",
	date = "date"
}