import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { hostConfig } from "../../config";
import { useNavigate } from "react-router-dom";
import {
  ReviewCard,
  ReviewSection,
  ReviewTitle,
  StudentsCard,
  StudentsCardWrap,
  StudentsDetails,
  StudentsName,
} from "../../pages/home/testimonialStyle";
import { gettoppostData } from "../../redux/action/reviewAction";
import { SearchTution } from "../../redux/action/searchAction";

import ProfileOne from "../../assets/images/no-image-2.png";
export const ReviewSectionCard = () => {
  const [postData, setPostData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Large desktop
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllPosts = () => {
    dispatch(gettoppostData()).then((res) => {
      console.log(res, "ressssssss form postssssss");
      setPostData(res?.records);
    });
  };

  useEffect(() => {
    getAllPosts();
  }, []);
  const handleCardClick = (item) => {
    SearchTution({
      area: "",
      batch_id: "",
      city: "",
      level: "",

      subject: "",
      tuition_name: item?.tuition_name, // timing: data?.selectTiming,
    });
    navigate(
      `${"/tuition-list"}?LOCATION_NAME=${""}&SELECT_AREA=${""}&SELECT_LEVEL=${""}&DAYS_OF_WEEK=${0}&TIME=${""}&SUBJECT_NAME=${""}&CITY_ID=${""}&TUITION_NAME=${
        item?.tuition_name
      }`
    );
  };
  return (
    <>
      <ReviewSection>
        <Container>
          <Row>
            <Col sm={12}>
              <ReviewTitle>Recent Posts From Tuitions</ReviewTitle>
              <ReviewCard>
                <Slider {...settings}>
                  {postData?.map((item) => {
                    return (
                      <StudentsCard key={item.id}>
                        <StudentsCardWrap
                          onClick={() => handleCardClick(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${hostConfig.S3_BUCKET}${item?.image_file_name}`}
                            alt={item?.image_file_name}
                            onError={(e) => {
                              e.target.src = ProfileOne; // Set to default image
                              e.target.onerror = null; // Remove the event handler to prevent infinite loop
                            }}
                          />
                          <h4>{item?.title}</h4>
                          <h6>{item?.sub_title}</h6>
                          {/* <StudentsDetails className="ellipse-text ">
                            {item?.short_description}
                          </StudentsDetails> */}
                        </StudentsCardWrap>
                      </StudentsCard>
                    );
                  })}
                </Slider>
              </ReviewCard>
            </Col>
          </Row>
        </Container>
      </ReviewSection>
    </>
  );
};
export default ReviewSectionCard;
