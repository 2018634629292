import * as React from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  Button,
} from "react-bootstrap";
import {
  BtnWrap

} from "../../pages/auth-old/authStyle";
import { ModalOverlay, ModalWrapper, ModalContent } from "../TuorPanel/Modal/modalStyle";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "3%",
  boxShadow: 24,
  p: 4,
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
};

export default function EmailVerifyModal(props: any) {
  const { open, handleClose, error } = props;

  return (
    <div>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      
      >
        <Box sx={style}>
          <Typography variant="h6" id="modal-modal-description" sx={{ mt: 2 }}>
            {error}
          </Typography>
          <Col sm={12}>
            <BtnWrap>
              <div style={{ textAlign: "center" }}>
              <Button
             
                type="submit"
                onClick={handleClose}
              >
                close
              </Button>
              </div>
            </BtnWrap>
          </Col>
        </Box>
      </Modal>
    </div>
  );
}
