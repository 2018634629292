import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { FaPhoneAlt, FaUserGraduate } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { MdLocationOn } from "react-icons/md";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import UserImage from "../../../assets/images/no-profile.png";
import RoundedButton from "../../../components/TuorPanel/Button";
// import Header from "../../../components/TuorPanel/Header";
import Header from "../../../components/Header";
import DynamicImageUpload from "../../../components/TuorPanel/ImageInput";
import StyledInput from "../../../components/TuorPanel/Input/input";
import Modal from "../../../components/TuorPanel/Modal/modal";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import { hostConfig } from "../../../config";
import {
  GetStudentProfileById,
  UpdateStudentProfileData,
} from "../../../redux/action/studentProfilePageAction";

const StudentProfile = () => {
  const [imageData, setImageData] = useState();
  const [base64, setBase64] = useState("");
  const handleImageSelect = (imageData, base64Image) => {
    setImageData(imageData);
    setBase64(base64Image);
    console.log("Selected image data:", imageData);
  };
  const dispatch = useDispatch();
  const [teacherID, setTeacherID] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [averageRating, setAverageRating] = useState();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    mobile_number: "",
    city: "",
    email_id: "",
    is_profile: "",
    profile_filename: "",
    is_active: true,
    first_name: "",
    last_name: "",
    // city: "",
    // location: "",
    // area: "",
  });

  const cookies = new Cookies();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("loggedUsers"));
    if (items) {
      // const foundUser = JSON.parse(items);

      setItems(items);
    }
  }, []);
  const initialUserData = async () => {
    const studentID = items;
    const id = studentID?.id;
    console.log(id, "id here");
    if(id!==undefined){
      setLoading(true);
      const result = await dispatch(GetStudentProfileById(id));
      setFormData(result?.records);
      setTeacherID(id);
    }

   
    setLoading(false);
  };
  console.log(imageData, "profiledata");
  useEffect(() => {
    setLoading(true);
    initialUserData();
    const rating = localStorage.getItem("Average Rating");
    const roundedRating = Math.round(parseFloat(rating));
    setAverageRating(roundedRating);
  }, [averageRating]);

  const [modal, setModal] = useState(false);
  const handleOpen = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(imageData, "1234567890");
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      // ["teacher_user_id"]: teacherID?.id,
      // ["location"]: formData?.city,
      // ["area"]: "",
      ["is_profile"]: true,
      ["profile_filename"]: imageData ? imageData : "", // Update profile_filename
    }));
  };

  const handleEditCLose = () => {
    setModal(false);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log(formData, "datas here");
    const updatedFormData = {
      ...e,
      ["profile_filename"]: imageData ? imageData : "", // Update profile_filename
      ["mobile_number"]: "",
      ["email_id"]: "",
      ["first_name"]: "",
      ["last_name"]: "",
    };
    if (base64 != "") {
      var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
      updatedFormData["base64_image"] = strImage;
    }
    try{
      // Dispatch the update action and wait for the response
      const response = await dispatch(
        UpdateStudentProfileData(updatedFormData, items.id)
      );
    console.log("Update successful:", response);

    if (response) {
      // Fetch the updated user data by ID
      const user = await dispatch(GetStudentProfileById(teacherID)).catch((error) => {
        console.error("Error fetching user data:", error);
        return null;
      });

      // Check if user and user.records are not null or undefined before updating local storage
      if (user && user.records) {
        // Update the local storage with the updated user data
        localStorage.setItem("loggedUsers", JSON.stringify(user.records));
      }
      // Reload the page after a delay (adjust timing as needed)
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      // Set the form data state (assuming the API response contains the updated data)
      setFormData(response.records);
      setModal(false);
    }}
    catch (error) {
      console.error("Error updating profile:", error);
    }
    
  
    initialUserData();
  };

  const validationSchema = Yup.object().shape({
    mobile_number: Yup.string()
      .matches(
        /^[6-9][0-9]{9}$/,
        "Mobile number must start with a digit from 6 to 9 and be 10 digits long"
      )
      .required("Mobile number is required"),
    email_id: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    city: Yup.string().required("Address is required"),
    education: Yup.string().required("Education is required"),
  });

  return (
    <>
     <Header />
      {/* <Header onClick={() => ""} searchBar={false} /> */}
      <StyledContainer>
        <div className="d-flex justify-content-between align-items-center m-3">
          <h6 className="prime-text fw-bolder mb-0">My Profile</h6>
          <RoundedButton variant="primary" onClick={handleOpen}>
            Edit Profile
          </RoundedButton>
        </div>

        {loading ? (
          <CustomPreloader />
        ) : (
          <StyledCard className="d-md-flex d-sm-block">
            <StyledLeftSide>
              <AvatarImage
                src={
                  formData?.is_profile
                    ? `${hostConfig.S3_BUCKET}${formData?.profile_filename}?lastdate=` +
                      Date()
                    : UserImage
                }
                alt=""
              />
              <h5 className="my-3">
                {formData?.first_name} {formData?.last_name}
              </h5>
              {/* <p style={{ color: "#4f6b73" }} className="text-center">
                Tuition Address - {formData?.city}
              </p> */}
              {/* <div className="w-100 d-flex justify-content-center position-absolute bottom-0">
                <StarRating
                  rating={averageRating}
                  totalStars={5}
                  starSize={12}
                />
                <p className="mx-2">
                  {averageRating} / 5 | &nbsp;
                  <Link to="/reviews">
                    {reviewCount} &nbsp;
                    <span>Reviews</span>
                  </Link>
                </p>
              </div> */}
            </StyledLeftSide>
            <StyledRightSide>
              <p>{formData?.discription}</p>
              <hr />
              <div className="grid-wrapper-profile">
                <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <FaUserGraduate />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Education</Title>
                    <Subtitle>{formData?.education}</Subtitle>
                  </TextWrapper>
                </section>
                {/* <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <IoCheckmarkCircle />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Availablity</Title>
                    <Subtitle>{formData?.availability}</Subtitle>
                  </TextWrapper>
                </section> */}
                {/* <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <TbCurrencyRupee />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Average Fee Per Subject Per Month</Title>
                    <Subtitle>{formData?.fee_per_month}</Subtitle>
                  </TextWrapper>
                </section> */}
                <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <MdLocationOn />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Location</Title>
                    <Subtitle>{formData?.city}</Subtitle>
                  </TextWrapper>
                </section>
                <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <FaPhoneAlt />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Phone</Title>
                    <Subtitle>{formData?.mobile_number}</Subtitle>
                  </TextWrapper>
                </section>
                <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <GrMail />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Email</Title>
                    <Subtitle>{formData?.email_id} </Subtitle>
                  </TextWrapper>
                </section>
                {/* <section className="d-flex align-items-center">
                  <IconWrapper>
                    <IconBackground>
                      <TfiWorld />
                    </IconBackground>
                  </IconWrapper>
                  <TextWrapper>
                    <Title>Website</Title>
                    <Subtitle>{formData?.website}</Subtitle>
                  </TextWrapper>
                </section> */}
              </div>
            </StyledRightSide>
          </StyledCard>
        )}
        {modal ? (
          <Modal
            title="Edit My Profile"
            hide={handleEditCLose}
            isShowing={modal}
            width="600px"
          >
            {/* <form>
              <div>
                <div className="row p-3 pb-0">
                  <DynamicImageUpload
                    onImageSelect={handleImageSelect}
                    name="profile_filename"
                  />
                  <div className="col-6 mt-3">
                    <StyledInput
                      width="100%"
                      type="number"
                      placeholder="Mobile Number"
                      name="mobile_number"
                      value={formData?.mobile_number}
                      disabled
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <StyledInput
                      width="100%"
                      type="email"
                      placeholder="Email ID"
                      disabled
                      name="email_id"
                      value={formData?.email_id}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <StyledInput
                      width="100%"
                      type="text"
                      name="city"
                      placeholder="Address"
                      onChange={handleChange}
                      value={formData?.city}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <StyledInput
                      width="100%"
                      type="text"
                      placeholder="Education"
                      onChange={handleChange}
                      value={formData?.education}
                      name="education"
                    />
                  </div>
                </div>
                <div className="my-3 text-left d-flex justify-content-center">
                  <RoundedButton variant="secondery">Cancel</RoundedButton>
                  <RoundedButton
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </RoundedButton>
                </div>
              </div>
            </form> */}
            <Formik
              initialValues={{
                mobile_number: formData?.mobile_number,
                email_id: formData?.email_id,
                city: formData?.city,
                education: formData?.education,
                // location: formData?.city,
                // is_profile: true,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <form>
                    <div>
                      <div className="row p-3 pb-0">
                        <DynamicImageUpload
                          url={
                            formData?.is_profile
                              ? `${hostConfig.S3_BUCKET}${formData?.profile_filename}?lastdate=` +
                                Date()
                              : null
                          }
                          onImageSelect={handleImageSelect}
                          name="profile_filename"
                        />
                        <div className="col-6 mt-3">
                          <StyledInput
                            width="100%"
                            type="number"
                            placeholder="Mobile Number"
                            name="mobile_number"
                            value={values?.mobile_number}
                            disabled
                          />
                          <div className="error">
                            {touched.mobile_number && errors.mobile_number && (
                              <p>{errors.mobile_number}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <StyledInput
                            width="100%"
                            type="email"
                            placeholder="Email ID"
                            disabled
                            name="email_id"
                            value={values?.email_id}
                          />
                          <div className="error">
                            {touched.email_id && errors.email_id && (
                              <p>{errors.email_id}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <StyledInput
                            width="100%"
                            type="text"
                            name="city"
                            placeholder="Address"
                            onChange={handleChange}
                            value={values?.city}
                          />
                          <div className="error">
                            {touched.city && errors.city && (
                              <p>{errors.city}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <StyledInput
                            width="100%"
                            type="text"
                            placeholder="Education"
                            onChange={handleChange}
                            value={values?.education}
                            name="education"
                          />
                          <div className="error">
                            {touched.education && errors.education && (
                              <p>{errors.education}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="my-3 text-left d-flex justify-content-center">
                        <RoundedButton variant="secondery">
                          Cancel
                        </RoundedButton>
                        <RoundedButton
                          variant="primary"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Save
                        </RoundedButton>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </Modal>
        ) : null}
      </StyledContainer>
    </>
  );
};

export default StudentProfile;

const StyledContainer = styled.div`
  margin: 20px;
  margin-top: 5rem;
`;

const StyledCard = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 10px;
`;

const StyledLeftSide = styled.div`
  background-color: #ffffff;
  padding: 30px;
  color: #000;
  position: relative;
  flex: 1;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align content to the center */
  border-top-left-radius: 10px; /* Rounded top left corner */
  border-bottom-left-radius: 10px; /* Rounded bottom left corner */
  border: 2px solid #940e34;
`;
const IconBackground = styled.div`
  background-color: #940e34; /* Background color for the icon */
  border-radius: 50%; /* Rounded shape */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 30px; /* Adjust the size for uniformity */
  height: 30px; /* Adjust the size for uniformity */
  color: #fff;
`;
const StyledRightSide = styled.div`
  background-color: #fff;
  padding: 40px;
  flex: 2;
  flex-wrap: wrap; /* Wrap to next row when content doesn't fit */
  justify-content: start; /* Evenly space sections in the row */
  border-top-right-radius: 10px; /* Rounded top right corner */
  border-bottom-right-radius: 10px; /* Rounded bottom right corner */
`;
const AvatarImage = styled.img`
  width: 80px;
  height: 80px;
  border: 3px solid #fff; /* White border with 2px width */
  border-radius: 50%; /* Rounded shape */
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  margin-right: 10px;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #940e34;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 14px;
  color: #888;
`;
