import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Rate } from "antd";
import { useNavigate } from "react-router-dom";

import { hostConfig } from "../../config";
import { getSpecificTuitionDetails } from "../../api/list";
import { convertRailwayTimeRangeToStandardTimeRange } from "../../utils/helper";

import BlockSpinner from "../../components/PageSpinner/BlockSpinner";
import RatingModal from "./RatingModal";
import UserImage from "../../assets/images/user-placeholder-small.png";

import {
  CardContainer,
  Logo,
  TuitionNameCard,
  Detail,
  RatingsContainer,
} from "./styledViews";


const BookingCard: React.FC<any> = ({ data, reviews, updateReviews }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<any>(null);
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const isDemo = data.booking.is_demo;
  const logoUrl = profile?.teacher_details.profile_filename || UserImage;
  const timing = useMemo(() => {
    if (!profile) return "-";
    const batch = _.find(profile?.batch, b => b.batch_id === data.booking.batch_id);
    if (!batch) return "-";
    return convertRailwayTimeRangeToStandardTimeRange(batch.timing);
  }, [profile]);
  const review = !isDemo && _.find(reviews, r => r.batch_id === data.booking.batch_id);

  const getTuitionDetails = () => {
    let params = {
      teacher_id: parseInt(data.booking.teacher_id)
    };
    getSpecificTuitionDetails(params).then((res) => {
      if(res.error || !res.success) {
        setLoading(false);
        // setPageError("Failed to fetch details.");
        return;
      }
      setProfile(res.records);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTuitionDetails();
  }, []);

  const handleClickRating = () => setShowReviewModal(true);

  const handleClickContent = () => navigate("/class-content/" + data.booking.batch_id);

  return <CardContainer>
    {(isLoading && <BlockSpinner height={300} />) ||
    (<div>
      <Logo><img src={logoUrl} alt="Tuition Logo" /></Logo>
      <TuitionNameCard>{profile.teacher_details.tuition_name}</TuitionNameCard>
      <Detail>Subject: {data.booking.subject}</Detail>
      <Detail>Date: {moment(data.booking.date).format("dddd, MMM DD, YYYY")}</Detail>
      <Detail>Timing: {timing}</Detail>

      {!isDemo && <RatingsContainer>
        {!review ? (
          <Button onClick={handleClickRating}>
            Rate your Tuition
          </Button>
        ) : (
          <Rate disabled defaultValue={review.start_count} />
        )}
      </RatingsContainer>}

      {!isDemo && <Button onClick={handleClickContent} style={{ marginTop: 20 }}>
        Study Material
      </Button>}
    </div>)}

    {showReviewModal && <RatingModal
      show onHide={() => setShowReviewModal(false)}
      logoUrl={logoUrl}
      name={profile.teacher_details.tuition_name}
      data={{
        student_id: parseInt(data.booking.student_id),
        teacher_id: parseInt(data.booking.teacher_id),
        batch_id: data.booking.batch_id
      }}
      updateReviews={updateReviews}
    />}
  </CardContainer>;
};

export default BookingCard;